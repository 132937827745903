import React, { useEffect } from "react";
import { graphql } from "gatsby";
import ParallaxHeader from "../components/ParallaxHeader";
import { motion } from "framer-motion";
import Markdown from "markdown-to-jsx";
import { MdDownload } from "react-icons/md";
import { Helmet } from "react-helmet";
export const PricingPageTemplate = ({
  prices,
  title,
  thead,
  listPdf,
  intro,
  footnote,
}) => {
  return (
    <div className="pt-[70px]  bg-beige-300/[0.3] flex flex-col items-center justify-center">
      <motion.h1
        initial={{ x: -10, opacity: 0 }}
        animate={{
          x: 0,
          opacity: 1,
          transition: {
            duration: 0.5,
            type: "ease",
          },
        }}
        className="z-10 my-10 text-navy text-center mx-5 text-5xl "
      >
        {title}
      </motion.h1>
      <div className="container flex flex-col px-2 md:px-20 justify-center items-center mx-20">
        <div className="w-full max-w-[1000px] text-left">
          {intro && (
            <Markdown className="md-content md-theme-color mb-4">
              {intro}
            </Markdown>
          )}
          <a
            className="text-navy hover:text-navyHover underline py-8"
            href={listPdf.publicURL}
            target="blank"
          >
            <MdDownload className="inline mr-3" />
            Download Pricing List
          </a>
        </div>
        <table className="price-table mx-1 md:mx-10 shadow-lg bg-white max-w-[1000px] w-full my-10">
          <thead>
            <tr>
              <th
                colSpan={2}
                className="text-center bg-navy text-white py-3 text-lg"
              >
                {thead}
              </th>
            </tr>
          </thead>
          <tbody>
            {prices.map((row, idx) => {
              return (
                <tr key={idx} className="px-5 border-y">
                  <td className="p-3 px-5 mr-10">
                    <b>{row.name}</b>
                  </td>
                  <td className="p-3 px-5 text-right">{row.price}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <p className="text-darkbrown/[0.5] mb-10">
          <em>{footnote}</em>
        </p>
      </div>
    </div>
  );
};

const PricingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  useEffect(() => {
    console.log(data);
  }, []);
  return (
    <>
      <Helmet>
        <title>Pricing - Brown Construction Services</title>
      </Helmet>
      <PricingPageTemplate
        title={frontmatter.title}
        prices={frontmatter.prices}
        intro={frontmatter.intro}
        thead={frontmatter.thead}
        listPdf={frontmatter.listPdf}
        footnote={frontmatter.footnote}
      />
    </>
  );
};
export default PricingPage;

export const pricingPageQuery = graphql`
  query PricingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        optionalintro
        thead
        listPdf {
          publicURL
        }
        prices {
          name
          price
        }
        footnote
      }
    }
  }
`;
